@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;500;800&display=swap');

body {
  margin: 0;
  font-family: Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  /*color: #001529 !important;*/
}

.ant-btn {
  box-shadow: 0 0px 0 !important;
}

.ant-btn-primary:hover {
  background-color: #f20505 !important;
}

.ant-table-cell {
  padding: 8px 8px !important;
}

.ant-radio-button-wrapper-checked.included {
  background: #008500 !important;
  border-color: #008500 !important;
}

.ant-radio-button-wrapper-checked.addition {
  background: #3a3a3aff !important;
  border-color: #3a3a3aff !important;
}

.ant-radio-button-wrapper-checked.unrealised {
  background: #fc0707 !important;
  border-color: #fc0707 !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #3a3a3aff;
}

.labelsEntries {
  background: #e8e8e8 !important;
  font-weight: 600;
  -webkit-font-smoothing: antialiased; /* For Chrome, Safari, and newer versions of Opera */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
  font-smooth: always; /* Standard property */
}

.mainCollaps .ant-collapse-content-box {
  padding: 0 0 0 20px !important;
}
