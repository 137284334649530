.register-forms {
  max-width: 450px;
  margin: 0 auto !important;
  overflow: hidden;
}

.registerBG {
  /*background-color: rgba(253, 253, 253, 0.75);*/
  max-width: 960px;
  min-width: 600px;
}
[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.ant-row.ant-form-item {
  margin-bottom: 0px;
}

#registerPage {
  padding: 30px;
  min-height: 100%;
  //min-width: 1024px;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center center;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

#registerPage input[type='text'] {
  width: 100% !important;
}

#registerPage .ant-picker {
  width: 100%;
}

#registerPage .center-vw {
  background: #ffffff80;
}

#registerPage img {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.center-vw-full {
  /*border-radius: 20px;*/
  padding: 20px 30px 20px 30px;
}

.steps-content {
  overflow: hidden;
  margin-top: 16px;
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}
